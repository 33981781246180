import { useAppKitAccount } from '@reown/appkit/react';
import md5 from 'blueimp-md5';
import { Filter } from 'lucide-react';
import React, { useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutlet } from 'react-router-dom';
import { PINATA_GATEWAY } from '../../../config/config.wods';
import { fetchPriceData } from '../../../services/api.fetchBalance';
import { fetchPredictions } from '../../../services/api.fetchPredictions';
import { StoreNames } from '../../../store/config';
import { setAddress } from '../../../store/reducers/addressReducers';
import {
	setFilteredMarkets,
	setMarketLoading,
	setMarkets,
} from '../../../store/reducers/marketReducers';
import Categories from './components/Categories';
import MarketGrid from './components/MarketGrid';
import TokenInfo from './components/TokenInfo';
import TrendingMarkets from './components/TrendingMarkets';

// Utility function to get Gravatar URL
const getGravatarUrl = (category, size = 200, id) => {
	const hash = md5(category.toLowerCase().trim() + id);
	return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=${size}`;
};

// Main App Component
function Campaign() {
	const id = useId();
	const outlet = useOutlet();
	const dispatch = useDispatch();
	const { markets, loadingMarkets, filteredMarkets } = useSelector(
		(state) => state[StoreNames.MARKET]
	);
	const [tokenData, setTokenData] = useState({
		price: null,
		priceChange: null,
		loading: true,
		error: null,
	});
	const [isSelectedCategory, setSelectedCategory] = useState('All');
	const [filterExpanded, setFilterExpanded] = useState(true);
	const [filterState, setFilterState] = useState({
		ACTIVE: false,
		INACTIVE: false,
		ALL: true,
	});
	const { ACTIVE, INACTIVE, ALL } = filterState;

	const { isConnected, address } = useAppKitAccount();

	// Fetch predictions and process the data
	useEffect(() => {
		if (markets.length !== 0) return;
		const fetchData = async () => {
			try {
				const result = await fetchPredictions();
				console.log(result);
				const processedData = result.reverse().map((data) => {
					let title = data.title;
					let description = data.title;
					let category = data?.category || 'crypto';
					let image = getGravatarUrl(category, 200, id);

					if (data.title.includes('{')) {
						try {
							const parsedData = JSON.parse(data.title);
							title = parsedData.name;
							description = parsedData.description;
							category = parsedData.category;
							if (parsedData['image-hash']) {
								image = `https://${PINATA_GATEWAY}/ipfs/${parsedData['image-hash']}`;
							}
						} catch (error) {
							console.error('Error parsing data title:', error);
						}
					}

					return { ...data, title, description, image, category };
				});

				dispatch(setMarkets(processedData)); // Initialize filtered data
			} catch (error) {
				console.error('Error fetching predictions:', error);
			} finally {
				dispatch(setMarketLoading(false)); // Set loading to false after prediction data is fetched
			}
		};

		fetchData();
	}, [dispatch, id]);

	// Fetch token data and address
	useEffect(() => {
		const fetchTokenData = async () => {
			try {
				const TOKEN_DATA = await fetchPriceData();
				setTokenData({
					price: TOKEN_DATA?.price || 'N/A',
					priceChange: TOKEN_DATA?.priceChange24h || 0,
					loading: false,
					error: null,
					...TOKEN_DATA,
				});
			} catch (error) {
				setTokenData((prev) => ({
					...prev,
					loading: false,
					error: error.message || 'Error fetching token data',
				}));
				console.error('Error fetching token data:', error);
			}
		};

		// const fetchAddress = async () => {
		//     try {
		//         if (!window.ethereum) {
		//             throw new Error(
		//                 'MetaMask is not installed. Please install MetaMask to connect your wallet.'
		//             );
		//         }
		//         const accounts = await window.ethereum.request({
		//             method: 'eth_requestAccounts',
		//         });
		//         dispatch(setAddress(accounts[0]));
		//     } catch (error) {
		//         console.error('Error fetching address:', error);
		//     }
		// };
        //TODO: use hook for address
		dispatch(setAddress(address));
		fetchTokenData();
		// fetchAddress();
	}, [dispatch]);

	// Filter predictions by category
	const filterPredictionDataByCategory = (selectedCategory) => {
		if (!selectedCategory || selectedCategory === 'All') {
			setSelectedCategory('All');
			if (ACTIVE) {
				dispatch(
					setFilteredMarkets(markets.filter((market) => new Date(market.date) > new Date()))
				);
			} else if (INACTIVE) {
				dispatch(
					setFilteredMarkets(markets.filter((market) => new Date(market.date) < new Date()))
				);
			} else {
				dispatch(setFilteredMarkets(markets));
			}
		} else {
			setSelectedCategory(selectedCategory);
			if (ACTIVE) {
				dispatch(
					setFilteredMarkets(
						markets.filter(
							(market) =>
								new Date(market.date) > new Date() &&
								market.category === selectedCategory.toLowerCase()
						)
					)
				);
			} else if (INACTIVE) {
				dispatch(
					setFilteredMarkets(
						markets.filter(
							(market) =>
								new Date(market.date) < new Date() &&
								market.category === selectedCategory.toLowerCase()
						)
					)
				);
			} else {
				dispatch(
					setFilteredMarkets(
						markets.filter((market) => market.category === selectedCategory.toLowerCase())
					)
				);
			}
		}
	};
	const filterDataPredictionByDate = (all, active, inactive) => {
		const currentDate = new Date();
		setFilterState({ ALL: all, ACTIVE: active, INACTIVE: inactive });

		// Filtering logic
		let filteredMarkets = markets;

		if (all) {
			// Both active and inactive are selected: show all markets
			filteredMarkets = markets;
		} else if (active) {
			// Only active markets
			filteredMarkets = markets.filter((market) => new Date(market.date) > currentDate);
		} else if (inactive) {
			// Only inactive markets
			filteredMarkets = markets.filter((market) => new Date(market.date) < currentDate);
		}

		// Further filter by category, if a category is selected
		if (isSelectedCategory && isSelectedCategory !== 'All') {
			filteredMarkets = filteredMarkets.filter(
				(market) => market.category === isSelectedCategory.toLowerCase()
			);
		}

		// Dispatch the filtered markets
		dispatch(setFilteredMarkets(filteredMarkets));
	};

	return (
		<>
			{/* <Helmet>
				<title>Wolf of Dumb Street ($WODS)</title>
				<meta name='description' content='Wolf of Dumb Street - Predict and Win the Dumb Money' />
			</Helmet> */}

			<div className='min-h-screen bg-[#0D0D0D] text-white no-scrollbar'>
				{outlet ? (
					outlet
				) : (
					<main className='container px-4 py-6 mx-auto space-y-8'>
						<TrendingMarkets tokenData={tokenData} predictionData={markets} />
						<Categories
							isSelectedCategory={isSelectedCategory}
							filterPredictionDataByCategory={filterPredictionDataByCategory}
						/>
						<div className='flex flex-col-reverse items-center justify-end w-full gap-4 overflow-x-hidden overflow-y-hidden lg:flex-row'>
							<div
								className={`relative flex flex-wrap gap-4 ${
									filterExpanded ? 'right-0' : '-right-full lg:h-min h-0'
								} transition-all duration-500 items-center justify-center`}
							>
								<button
									onClick={() => {
										filterDataPredictionByDate(true, false, false);
									}}
									className={`font-semibold  !rounded-lg py-2 px-4 ${
										ALL ? 'bg-yellow-500 text-black' : 'bg-yellow-500/20 text-yellow-500'
									}`}
								>
									{' '}
									All Markets
								</button>
								<button
									onClick={() => {
										filterDataPredictionByDate(false, true, false);
									}}
									className={`font-semibold  !rounded-lg py-2 px-4 ${
										ACTIVE ? 'bg-yellow-500 text-black' : 'bg-yellow-500/20 text-yellow-500'
									}`}
								>
									{' '}
									Active Markets
								</button>
								<button
									onClick={() => {
										filterDataPredictionByDate(false, false, true);
									}}
									className={`font-semibold  !rounded-lg py-2 px-4 ${
										INACTIVE ? 'bg-yellow-500 text-black' : 'bg-yellow-500/20 text-yellow-500'
									}`}
								>
									{' '}
									Ended Markets
								</button>
							</div>
							<button
								disabled={loadingMarkets}
								className='py-2 disabled:cursor-not-allowed disabled:opacity-50'
								onClick={() => setFilterExpanded((prev) => !prev)}
							>
								<Filter className='w-6 h-6 text-yellow-500' />
							</button>
						</div>
						{loadingMarkets ? (
							<div className='flex items-center justify-center text-lg text-white'>
								Loading data, please wait...
							</div>
						) : (
							<>
								<div className='grid justify-center w-full grid-cols-1 gap-3 lg:grid-cols-3 md:grid-cols-2'>
									{filteredMarkets.map((elem, index) => (
										<MarketGrid key={`market-${index}`} data={elem} category={isSelectedCategory} />
									))}
								</div>
							</>
						)}
						<TokenInfo tokenData={tokenData} />
					</main>
				)}
			</div>
		</>
	);
}

export default Campaign;
