import { Twitter } from 'lucide-react';
import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { SiFarcaster } from 'react-icons/si';
import { DOC, HOME, SOCIAL_LINKS } from '../../config/config.wods';

export default function Footer() {
	return (
		<footer className='py-8 text-center bg-black border-t border-yellow-500/20 md:text-left'>
			<div className='container px-4 mx-auto'>
				<div className='grid grid-cols-1 gap-8 md:grid-cols-3'>
					<div>
						<h3 className='mb-4 text-lg font-bold text-yellow-500'>THE DUMB STREET</h3>
						<p className='text-sm text-gray-400'>
							The most degen prediction market in crypto. Built by degens, for degens.
						</p>
					</div>

					<div className='text-center'>
						<h4 className='mb-4 font-semibold'>Quick Links</h4>
						<ul className='space-y-2 text-sm text-gray-400'>
							{/* <li>
								<Link to='/markets' className='hover:text-yellow-500'>
									Markets
								</Link>
							</li>
							<li>
								<Link to='/leaderboard' className='hover:text-yellow-500'>
									Leaderboard
								</Link>
							</li>
							<li>
								<Link to='/token' className='hover:text-yellow-500'>
									Token
								</Link>
							</li> */}
							<li>
								<a
									href={HOME}
									className='hover:text-yellow-500'
									target='_blank'
									rel='noopener noreferrer'
								>
									Home
								</a>
							</li>
							<li>
								<a
									href={DOC}
									className='hover:text-yellow-500'
									target='_blank'
									rel='noopener noreferrer'
								>
									Documentation
								</a>
							</li>
							<li>
								<a
									href={SOCIAL_LINKS.DEXSCREENER}
									className='hover:text-yellow-500'
									target='_blank'
									rel='noopener noreferrer'
								>
									Dexcscreener
								</a>
							</li>
							<li>
								<a
									href={SOCIAL_LINKS.DEXTOOLS}
									className='hover:text-yellow-500'
									target='_blank'
									rel='noopener noreferrer'
								>
									DexTools
								</a>
							</li>
							<li>
								<a
									href={SOCIAL_LINKS.UNISWAP}
									className='hover:text-yellow-500'
									target='_blank'
									rel='noopener noreferrer'
								>
									Uniswap
								</a>
							</li>
							<li>
								<a
									href={'https://wodsfun.hashnode.space/default-guide/terms/terms-and-conditions '}
									className='hover:text-yellow-500'
									target='_blank'
									rel='noopener noreferrer'
								>
									Terms and Conditions
								</a>
							</li>
						</ul>
					</div>

					{/* <div>
						<h4 className='mb-4 font-semibold'>Resources</h4>
						<ul className='space-y-2 text-sm text-gray-400'>
							<li>
								<Link to='/faq' className='hover:text-yellow-500'>
									FAQ
								</Link>
							</li>
							<li>
								<Link to='/terms' className='hover:text-yellow-500'>
									Terms of Service
								</Link>
							</li>
							<li>
								<Link to='/privacy' className='hover:text-yellow-500'>
									Privacy Policy
								</Link>
							</li>
							<li>
								<Link to='/contact' className='hover:text-yellow-500'>
									Contact
								</Link>
							</li>
						</ul>
					</div> */}

					<div className='mx-auto'>
						<h4 className='mb-4 font-semibold'>Community</h4>
						<div className='flex items-center justify-center space-x-4'>
							<a
								href={SOCIAL_LINKS.X}
								className='text-gray-400 hover:text-yellow-500'
								target='_blank'
								rel='noopener noreferrer'
							>
								<Twitter className='w-5 h-5' />
							</a>
							<a
								href={SOCIAL_LINKS.TG}
								className='text-gray-400 hover:text-yellow-500'
								target='_blank'
								rel='noopener noreferrer'
							>
								<FaTelegramPlane className='w-5 h-5' />
							</a>
							<a
								href={SOCIAL_LINKS.FARCASTER}
								className='text-gray-400 hover:text-yellow-500'
								target='_blank'
								rel='noopener noreferrer'
							>
								<SiFarcaster className='w-5 h-5' />
							</a>
						</div>
					</div>
				</div>

				<div className='pt-8 mt-8 text-sm text-center text-gray-400 border-t border-yellow-500/20'>
					© {new Date().getFullYear()}{' '}
					<a className='text-yellow-500' href={HOME}>
						wods.fun
					</a>
					.
				</div>
			</div>
		</footer>
	);
}
