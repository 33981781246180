import { BrowserProvider, ethers, JsonRpcSigner } from 'ethers';
import { PREDICTO_ABI } from '../config/abi/predicto.abi';
import { TOKEN_CONTRACT_ABI } from '../config/abi/token.abi';
import { WOODS_TOKEN } from '../config/config.wods';
export const handlePrediction = async ({ addr, amount, index, walletProvider, chainId, isConnected, address }) => {
	try {
		if (!isConnected) {
			alert('Please connect your wallet');
			return;
		}
		const provider = new BrowserProvider(walletProvider, chainId);
		const signer = new JsonRpcSigner(provider, address);

		const approveContractInstace = new ethers.Contract(WOODS_TOKEN, TOKEN_CONTRACT_ABI, signer);
		const approveResponse = await approveContractInstace.approve(
			addr,
			ethers.parseUnits(amount.toString(), 18)
		);
		await approveResponse.wait();
		const participateContractInstance = new ethers.Contract(addr, PREDICTO_ABI, signer);
		const participateTrx = await participateContractInstance.participate(
			index,
			ethers.parseUnits(amount.toString(), 18)
		);
		const receipt = await participateTrx.wait();
		return receipt?.hash;
	} catch (err) {
		console.log(err);
	}
};
