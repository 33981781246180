import { useAppKitAccount, useAppKitNetworkCore, useAppKitProvider } from '@reown/appkit/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateImageUrl } from '../../../../../config/helper-functions';
import { fetchPredictions } from '../../../../../services/api.fetchPredictions';
import { isClaimed } from '../../../../../services/api.isClaimed';
import { StoreNames } from '../../../../../store';
import { setMarkets } from '../../../../../store/reducers/marketReducers';
import { MarketGrid } from './MarketGrid';

const AnnounceCampaign = () => {
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(true);
	const { markets } = useSelector((state) => state[StoreNames.MARKET]);
	const { address, isConnected } = useAppKitAccount();
	const { walletProvider } = useAppKitProvider('eip155');
	const { chainId } = useAppKitNetworkCore();

	const fetchCampaignData = async () => {
		setLoading(true); // Explicitly set loading state
		try {
			const result = await fetchPredictions();

			const processedData = await Promise.all(
				result.reverse().map(async (data) => {
					const { title: rawTitle = '', category: rawCategory = 'crypto' } = data;

					let title = rawTitle;
					let description = rawTitle;
					let category = rawCategory;
					let image;

					// Parse the title if it includes a JSON object
					if (rawTitle.includes('{')) {
						try {
							const parsedData = JSON.parse(rawTitle);
							title = parsedData.name || rawTitle;
							description = parsedData.description || rawTitle;
							category = parsedData.category || rawCategory;
							image = generateImageUrl(category, address, parsedData['image-hash']);
						} catch (error) {
							console.error('Error parsing campaign title:', error);
						}
					}

					// Default image if not set during parsing
					image = image || generateImageUrl(category, address);

					let claimed = null;
					if (data.finalOutcome) {
						try {
							claimed = await isClaimed({
								address,
								chainId,
								walletProvider,
								isConnected,
								marketAddress: data.address,
							});
						} catch (error) {
							console.error(`Error fetching claim status for campaign ${data.id}:`, error);
						}
					}

					return { ...data, title, description, image, category, claimed };
				})
			);
			dispatch(setMarkets(processedData));
		} catch (error) {
			console.error('Error fetching campaign data:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCampaignData();
	}, []);

	// Memoize filtered user campaigns
	const userCampaigns = useMemo(
		() =>
			markets.filter(
				(campaign) => address && campaign.admin?.toLowerCase() === address.toLowerCase()
			),
		[markets, address]
	);

	if (isLoading) {
		return <div>Loading data, please wait...</div>;
	}

	if (!address) {
		return <div>Please connect your wallet.</div>;
	}

	if (!userCampaigns.length) {
		return <div>No data found</div>;
	}

	return (
		<div className='grid justify-center w-full grid-cols-1 gap-3 lg:grid-cols-3 md:grid-cols-2'>
			{userCampaigns.map((campaign, index) => (
				<div key={campaign.id || campaign.admin}>
					<MarketGrid
						fetchCampaignData={fetchCampaignData}
						key={index}
						data={campaign}
						type='announce'
					/>
				</div>
			))}
		</div>
	);
};

export default AnnounceCampaign;
