import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DEX from '../../assets/dex-screener-seeklogo.svg';
import UNISWAP from '../../assets/uniswap-seeklogo.svg';
import { CHART_NAV_LINK, UNISWAP_NAV_LINK } from '../../config/config.wods';
import { connectWallet } from '../../services/api.connectWallet';
import { StoreNames } from '../../store';
import { setAddress } from '../../store/reducers/addressReducers';
import TutorialDialog from './tutorial-dialog';

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isExpanded, setExpanded] = useState(false);
    const [isOpenTutorial, setOpenTutorial] = useState(false);
    const [error, setError] = useState(null);
    const { address } = useSelector((state) => state[StoreNames.ADDRESS]);

    useEffect(() => {
        // Redirect to /predict if wallet is disconnected and current route is /profile
        if (!address && window.location.pathname === '/profile') {
            navigate('/predict');
        }
    }, [address, navigate]);

    const handleConnectWallet = async () => {
        try {
            const { walletAddress } = await connectWallet();
            dispatch(setAddress(walletAddress));
            setError(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDisconnectWallet = () => {
        // Clear wallet address from Redux store
        dispatch(setAddress(null));

        // Clear wallet address from localStorage
        localStorage.removeItem('walletAddress');
    };

    const formatWalletAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    return (
        <>
            <nav className="bg-black border-b border-yellow-500/20">
                <div className="container p-4 mx-auto">
                    <div className="flex items-center justify-between">
                        <div>
                            <Link
                                className="flex items-center space-x-4"
                                to={'/'}
                            >
                                <div className="flex flex-col">
                                    <div className="text-xl font-bold text-yellow-500">
                                        THE DUMB STREET
                                    </div>
                                </div>
                            </Link>
                            <button
                                onClick={() => setOpenTutorial(true)}
                                className="text-md subtext hover:underline"
                            >
                                [HOW IT WORKS]
                            </button>
                        </div>
                        <div className="items-center hidden gap-x-12 lg:flex">
                            <Link
                                to="/predict"
                                className={`cursor-pointer ${
                                    window.location.pathname === '/predict' &&
                                    'text-yellow-400'
                                }`}
                            >
                                [All markets]
                            </Link>
                            <Link
                                to="/launch-market"
                                className={`cursor-pointer ${
                                    window.location.pathname ===
                                        '/launch-market' && 'text-yellow-400'
                                }`}
                            >
                                [Create]
                            </Link>
                            {/* <Link
								to='/meme'
								className={`cursor-pointer ${
									window.location.pathname === '/meme' && 'text-yellow-400'
								}`}
							>
								Meme
							</Link> */}
                        </div>

                        <div className="items-center hidden space-x-4 lg:flex">
                            <a
                                href={CHART_NAV_LINK}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={DEX}
                                    alt="profile"
                                    className="w-6 h-6 mt-2 rounded-full"
                                />
                            </a>
                            <a
                                href={UNISWAP_NAV_LINK}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={UNISWAP}
                                    alt="profile"
                                    className="w-8 h-8 text-white rounded-full"
                                />
                            </a>
                            {/* {address ? (
                                <div className="flex items-center px-3 py-2 space-x-2 bg-yellow-500 rounded-md">
                                    <span className="font-semibold text-black">
                                        {formatWalletAddress(address)}
                                    </span>
                                    <button
                                        onClick={handleDisconnectWallet}
                                        className="text-black hover:text-red-600"
                                    >
                                        <X className="w-4 h-4" />
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <button
                                        onClick={handleConnectWallet}
                                        className="items-center hidden px-4 py-2 font-semibold text-black transition-colors bg-yellow-500 rounded-md lg:flex hover:bg-yellow-400"
                                    >
                                        <Wallet className="w-4 h-4 mr-2" />
                                        Connect Wallet
                                    </button>
                                </>
                            )} */}
                            <appkit-button />
                            {address && (
                                <>
                                    <button
                                        onClick={() =>
                                            navigate(`profile/${address}`)
                                        }
                                        className=""
                                    >
                                        <div className="text-lg">[PROFILE]</div>
                                    </button>
                                </>
                            )}
                        </div>
                        <button
                            className="lg:hidden"
                            onClick={() => setExpanded(!isExpanded)}
                        >
                            <Menu className="w-6 h-6 text-yellow-500" />
                        </button>
                    </div>
                </div>
            </nav>

            {isExpanded && (
                <div className="flex flex-col gap-4 py-4 text-center text-white bg-black lg:hidden">
                    <Link to="/predict" className="block">
                        Predictions
                    </Link>
                    <Link to="/launch-market" className="block">
                        Launch New Market
                    </Link>
                    {/* <Link to='/meme' className='block py-2'>
						Meme
					</Link> */}
                    <div className="mx-auto">
                        <appkit-button />
                    </div>
                    {/* {address ? (
						<>
							<button onClick={() => navigate(`profile/${address}`)} className=''>
								<div>[PROFILE]</div>
							</button>

							<div className='flex items-center px-3 py-2 mx-auto space-x-2 bg-yellow-500 rounded-md w-max'>
								<span className='font-semibold text-black'>{formatWalletAddress(address)}</span>
								<button onClick={handleDisconnectWallet} className='text-black hover:text-red-600'>
									<X className='w-4 h-4' />
								</button>
							</div>
						</>
					) : (
						<button
							onClick={handleConnectWallet}
							className='flex items-center px-4 py-2 mx-auto font-semibold text-black transition-colors bg-yellow-500 rounded-md lg:hidden hover:bg-yellow-400'
						>
							<Wallet className='w-4 h-4 mr-2' />
							Connect Wallet
						</button>
					)} */}
                    <div className="flex items-center justify-center gap-4">
                        <a
                            href={CHART_NAV_LINK}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={DEX}
                                alt="profile"
                                className="w-6 h-6 mx-auto mt-2 rounded-full"
                            />
                        </a>
                        <a
                            href={UNISWAP_NAV_LINK}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={UNISWAP}
                                alt="profile"
                                className="w-8 h-8 mx-auto text-white rounded-full"
                            />
                        </a>
                    </div>
                </div>
            )}

            {error && (
                <div className="mt-2 text-center text-red-500">{error}</div>
            )}
            {isOpenTutorial && (
                <TutorialDialog onClose={() => setOpenTutorial(false)} />
            )}
        </>
    );
}
