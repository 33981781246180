import { BrowserProvider, ethers, JsonRpcSigner } from 'ethers';
import { PREDICTO_ABI } from '../config/abi/predicto.abi';

export const annouceWinner = async ({ marketAddress, selectedIndex, walletProvider, chainId, isConnected, address }) => {
	try {
		if (!isConnected) {
			throw new Error('Please connect your wallet');
		}
		const provider = new BrowserProvider(walletProvider, chainId);
		const signer = new JsonRpcSigner(provider, address);
		const predictionContractInstance = new ethers.Contract(marketAddress, PREDICTO_ABI, signer);
		const predictionTrx = await predictionContractInstance.resolveMarket(selectedIndex);
		const receipt = await predictionTrx.wait();
		return receipt?.hash;
	} catch (err) {
		console.log(err);
	}
};
