import React from 'react';
import config from '../../config/config.wods';

const TutorialDialog = ({ onClose }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 backdrop-blur-lg">
            <div className="bg-[#0D0D0D] border border-yellow-500/20 rounded-lg w-full min-w-[55vw] max-w-lg max-h-[90vh] overflow-auto relative">
                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-white text-2xl font-bold hover:text-red-500 z-50"
                >
                    [ ❌ ]
                </button>

                <div className="relative flex flex-col justify-between gap-4 px-4 pb-4 border-b border-yellow-500/20">
                    <h2 className="sticky top-0 w-full text-2xl font-bold text-center bg-[#0D0D0D] pt-4">
                        HOW IT WORKS
                    </h2>
                    <div className="!text-white font-semibold py-4 text-center">
                        <span className="mr-2 text-yellow-500 cursor-pointer hover:underline">
                            Wods.fun
                        </span>
                        allows anyone to create new prediction markets and
                        participate by using $WODS on Base.
                    </div>
                    <div className="grid grid-cols-4">
                        <div>Step 1:</div>
                        <div className="col-span-3">
                            Get $WODS tokens on Base:{' '}
                            <a
                                href={config.SOCIAL_LINKS.UNISWAP}
                                target="_blank"
                                rel="noreferrer"
                                className="text-yellow-500 underline hover:text-yellow-400"
                            >
                                Uniswap
                            </a>
                        </div>
                    </div>
                    <div className="grid grid-cols-4">
                        <div>Step 2:</div>
                        <div className="col-span-3">
                            Create prediction market on any topic of your
                            imagination (e.g. "Will We see Aliens by 2026?") by
                            connecting your wallet.
                        </div>
                    </div>
                    <p className="text-center">OR</p>
                    <p>Buy or sell positions in markets you believe in.</p>
                    <div className="grid grid-cols-4">
                        <div>Step 3:</div>
                        <div className="col-span-3">
                            Participate in existing markets by connecting your
                            wallet and buying YES or NO.
                        </div>
                    </div>
                    <div className="grid grid-cols-4">
                        <div>Step 4:</div>
                        <div className="col-span-3">
                            Market creator can resolve markets they create. So
                            verify the markets you participate on.
                        </div>
                    </div>
                    <p className="font-semibold text-center">
                        Prediction markets are simple: if you think something
                        will happen, buy
                        <span className="text-green-500/70"> YES</span>. If you
                        don't, buy
                        <span className="text-red-500/70"> NO</span>. The more
                        confident people are in an outcome, the more they buy,
                        moving the price. When the event happens, winners get
                        paid in
                        <span className="text-yellow-500"> $WODS</span>.
                    </p>
                    <p className="text-center">
                        <p>By using wods.fun, you agree to these</p>
                        <a
                            href="https://wodsfun.hashnode.space/default-guide/terms/terms-and-conditions"
                            target="_blank"
                            rel="noreferrer"
                            className="ml-1 text-yellow-500 underline hover:text-yellow-400"
                        >
                            Terms and Conditions
                        </a>
                    </p>
                    <button
                        onClick={onClose}
                        className="text-lg hover:underline hover:font-semibold text-green-500"
                    >
                        [START PLAYING]
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TutorialDialog;
