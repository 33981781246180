import { BrowserProvider, ethers, JsonRpcSigner } from 'ethers';
import { TOKEN_LOCKING_ABI } from '../config/abi/locking.abi';
import { TOKEN_LOCKING_CONTRACT } from '../config/config.wods';
export const isClaimed = async ({
	address,
	walletProvider,
	chainId,
	isConnected,
	marketAddress,
}) => {
	try {
		if (!isConnected) {
			throw new Error('Wallet not connected.');
		}
		const provider = new BrowserProvider(walletProvider, chainId);
		const signer = new JsonRpcSigner(provider, address);
		const tokenLockingContract = new ethers.Contract(
			TOKEN_LOCKING_CONTRACT,
			TOKEN_LOCKING_ABI,
			signer
		);
		const isClaimed = await tokenLockingContract.isClaimed(marketAddress);
		return isClaimed;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};
