import { BrowserProvider, ethers, JsonRpcSigner } from 'ethers';
import { PREDICTO_ABI } from '../config/abi/predicto.abi';

export const withdrawFunds = async ({
	address,
	walletProvider,
	chainId,
	marketAddress,
	isConnected,
}) => {
	try {
		if (!isConnected) {
			throw new Error('Please connect your wallet');
		}
		const provider = new BrowserProvider(walletProvider, chainId);
		const signer = new JsonRpcSigner(provider, address);
		const withdrawContractInstace = new ethers.Contract(marketAddress, PREDICTO_ABI, signer);
		const withdrawTrx = await withdrawContractInstace.withdrawWinnings();
		const receipt = await withdrawTrx.wait();

		return receipt?.hash;
	} catch (err) {
		console.log(err);
	}
};
