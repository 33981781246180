import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeContextProvider } from './views/components/context/ThemeContext';
import CampaignForm from './views/pages/CreatePrediction';
import Campaigns from './views/pages/Prediction';
// import MarketPage from './views/pages/Prediction/components/MarketPage';
import { Toaster } from 'react-hot-toast';
import Footer from './views/components/Footer';
import Header from './views/components/Header';
import MarketPage from './views/pages/Prediction/components/MarketPage';
import { ProfilePage } from './views/pages/Profile';
import { base } from '@reown/appkit/networks';
import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';

// 1. Get projectId
const projectId = 'e14bd40f67fc2123839112020d3a9408';

// 2. Set the networks
const networks = [base];

// 3. Create a metadata object - optional
const metadata = {
    name: 'WODS',
    description: 'WODS | Anon Prediction Market',
    url: 'https://wods.fun', // origin must match your domain & subdomain
    icons: ['https://assets.reown.com/reown-profile-pic.png'],
};

// 4. Create a AppKit instance
createAppKit({
    adapters: [new EthersAdapter()],
    networks,
    metadata,
    projectId,
    features: {
        analytics: false, // Optional - defaults to your Cloud configuration
        email: false, // default to true
        socials: false,
        emailShowWallets: false, // default to true
        onramp: false,
        swaps: false,
        send: false,
    },
});

export default function App() {
    return (
        <ThemeContextProvider>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                    duration: 5000,
                    style: { background: '#363636', color: '#fff' },
                    success: { duration: 3000 },
                }}
            />
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/predict" replace />}
                    />
                    <Route path="/predict" element={<Campaigns />}>
                        <Route path=":address" element={<MarketPage />} />
                    </Route>
                    {/* <Route path="/meme" element={<Meme />} /> */}
                    <Route path="/launch-market" element={<CampaignForm />} />
                    <Route path="/profile/:address" element={<ProfilePage />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </ThemeContextProvider>
    );
}
