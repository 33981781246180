import { Monitor, Music, Star, Trophy, Vote, Wallet, Zap } from 'lucide-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreNames } from '../../../../store';

const categories = [
	{ id: 'All', name: 'All', icon: Star },
	{ id: 'crypto', name: 'Crypto', icon: Wallet },
	{ id: 'technology', name: 'Technology', icon: Monitor },
	{ id: 'culture', name: 'Culture', icon: Music },
	{ id: 'politics', name: 'Politics', icon: Vote },
	{ id: 'sports', name: 'Sports', icon: Trophy },
	{ id: 'breaking', name: 'Breaking', icon: Zap },
];

export default function Categories(props) {
	const { loadingMarkets } = useSelector((state) => state[StoreNames.MARKET]);
	const categorySelectedStyle =
		'bg-yellow-500/20 border border-yellow-500/20 rounded-lg hover:border-yellow-500/40 transition-colors';
	const categoryStyle =
		'bg-black border border-yellow-500/20 rounded-lg hover:border-yellow-500/40 transition-colors';
	return (
		<section className='py-6'>
			<div className='flex flex-row gap-4 overflow-x-scroll no-scrollbar'>
				{categories.map(({ id, name, icon: Icon }) => (
					<button
						disabled={loadingMarkets}
						key={id}
						className={`flex flex-col items-center justify-center p-4 ${name === props.isSelectedCategory ? categorySelectedStyle : categoryStyle
							} disabled:cursor-not-allowed disabled:opacity-50 transition-colors w-full min-w-[150px]`}
						onClick={() => props.filterPredictionDataByCategory(name)}
					>
						<Icon className='w-6 h-6 mb-2 text-yellow-500' />
						<span className='text-sm font-medium'>{name}</span>
					</button>
				))}
			</div>
		</section>
	);
}
