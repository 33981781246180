export const TOKEN_LOCKING_ABI = [
	{
		type: 'constructor',
		inputs: [{ name: 'tokenAddress', type: 'address', internalType: 'address' }],
		stateMutability: 'nonpayable',
	},
	{
		type: 'function',
		name: 'getLockedTokens',
		inputs: [{ name: 'market', type: 'address', internalType: 'address' }],
		outputs: [{ name: '', type: 'uint256', internalType: 'uint256' }],
		stateMutability: 'view',
	},
	{
		type: 'function',
		name: 'isClaimed',
		inputs: [{ name: 'market', type: 'address', internalType: 'address' }],
		outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
		stateMutability: 'view',
	},
	{
		type: 'function',
		name: 'lockTokens',
		inputs: [
			{ name: 'market', type: 'address', internalType: 'address' },
			{ name: 'amount', type: 'uint256', internalType: 'uint256' },
			{ name: 'creator', type: 'address', internalType: 'address' },
		],
		outputs: [],
		stateMutability: 'nonpayable',
	},
	{
		type: 'function',
		name: 'unlockTokens',
		inputs: [{ name: 'market', type: 'address', internalType: 'address' }],
		outputs: [],
		stateMutability: 'nonpayable',
	},
	{
		type: 'event',
		name: 'TokensLocked',
		inputs: [
			{ name: 'market', type: 'address', indexed: true, internalType: 'address' },
			{ name: 'creator', type: 'address', indexed: true, internalType: 'address' },
			{ name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
		],
		anonymous: false,
	},
	{
		type: 'event',
		name: 'TokensUnlocked',
		inputs: [
			{ name: 'market', type: 'address', indexed: true, internalType: 'address' },
			{ name: 'creator', type: 'address', indexed: true, internalType: 'address' },
			{ name: 'amount', type: 'uint256', indexed: false, internalType: 'uint256' },
		],
		anonymous: false,
	},
	{
		type: 'error',
		name: 'SafeERC20FailedOperation',
		inputs: [{ name: 'token', type: 'address', internalType: 'address' }],
	},
];
