import { useAppKitAccount, useAppKitNetworkCore, useAppKitProvider } from '@reown/appkit/react';
import { AlertCircle, X } from 'lucide-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaDollarSign } from 'react-icons/fa6';
import { FiExternalLink } from 'react-icons/fi';
import CONFIG from '../../../../config/config.wods';
import { handlePrediction } from '../../../../services/api.participate';

export function VotingModal({ data, onClose, selectedIndex, imageURL }) {
	const { walletProvider } = useAppKitProvider('eip155');
	const { chainId } = useAppKitNetworkCore();
	const [amount, setAmount] = useState(data.minPrice); // Default to minPrice
	const [sliderValue, setSliderValue] = useState(data.minPrice); // Default to minPrice
	const [showFullDescription, setShowFullDescription] = useState(false);
	const [loading, setLoading] = useState(false);
	const [link, setLink] = useState('');
	const { address, isConnected } = useAppKitAccount();

	const [showSharePopup, setShowSharePopup] = useState(false);
	const [shareLink, setShareLink] = useState('');
	const [explorerLink, setExplorerLink] = useState('');

	const win = selectedIndex === 0 ? data.options1[1] : data.options2[1];
	const f = parseInt(win) / 1e18;
	const final = (amount / (f + amount)) * (data.total_rewards + amount);

	const { minPrice, maxPrice } = data;

	const handleAmountChange = (value) => {
		const numericValue = parseFloat(value);
		setAmount(numericValue);
		setSliderValue(numericValue);
	};

	const handleSliderChange = (value) => {
		const numericValue = parseFloat(value);
		setSliderValue(numericValue);
		setAmount(numericValue);
	};

	const handleParticipateUser = async () => {
		if (!amount) return toast.error('Please enter an amount');
		if (loading || link) return;
		if (amount < minPrice || amount > maxPrice) {
			toast.error(`Amount must be between ${minPrice} and ${maxPrice}`);
			return;
		}

		try {
			setLoading(true);
			const d = await handlePrediction({
				addr: data.address,
				amount: amount,
				index: selectedIndex,
				chainId,
				walletProvider,
				isConnected,
				address,
			});
			if (d) {
				setLink(d);
				const link = `${CONFIG.CHAIN_CONFIG.blockExplorerUrls[0]}tx/${d}`;
				const WODS_LINK = `https://${window.location.host}/predict/${data.address}`;
				setShareLink(
					`https://twitter.com/intent/tweet?text=${encodeURIComponent(
						`Placed my vote on WODS on Base.\nWhat do you guys think? Vote here:\n${WODS_LINK}\n`
					)}`
				);
				setShowSharePopup(true);
				setExplorerLink(link);
			} else {
				toast.error('You cannot participate more than once.');
			}
		} catch (err) {
			toast.error('Something went wrong');
		} finally {
			setLoading(false);
		}
	};

	let title = data.title;
	let description = data.title;

	if (data.title.includes('{')) {
		const resolvedData = JSON.parse(data.title);
		title = resolvedData.name;
		description = resolvedData.description;
	}

	let descriptionJSX = <p className='text-sm leading-relaxed text-gray-300'>{description}</p>;
	if (description.length > 50) {
		descriptionJSX = (
			<p className='text-sm leading-relaxed text-gray-300'>
				{showFullDescription ? description : `${description.split(' ').slice(0, 10).join(' ')}...`}
				<button
					onClick={() => setShowFullDescription(!showFullDescription)}
					className='ml-2 font-medium text-yellow-500 underline hover:text-yellow-400'
				>
					{showFullDescription ? 'show less' : 'show more'}
				</button>
			</p>
		);
	}

	// Function to determine step value based on min and max prices
	const getStepValue = (min, max) => {
		// Convert numbers to strings to check their decimal places
		const minStr = min.toString();
		const maxStr = max.toString();

		// Get decimal places length (if any)
		const minDecimals = minStr.includes('.') ? minStr.split('.')[1].length : 0;
		const maxDecimals = maxStr.includes('.') ? maxStr.split('.')[1].length : 0;

		// Use the larger number of decimal places
		const decimals = Math.max(minDecimals, maxDecimals);

		// If no decimals, return 1, otherwise return appropriate decimal step
		return decimals === 0 ? '1' : `${1 / Math.pow(10, decimals)}`;
	};

	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80'>
			{showSharePopup && (
				<div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
					<div className='relative p-6 py-10 text-center bg-gray-800 rounded-lg shadow-lg'>
						<button
							onClick={() => setShowSharePopup(false)}
							className='absolute top-[-8px] right-[-8px] bg-yellow-500 text-black w-6 h-6 flex items-center justify-center rounded-full hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-white'
							aria-label='Close'
						>
							X
						</button>
						<h2 className='mb-2 text-lg font-bold text-white'>You vote is successful! 🎉 🎉</h2>
						<h2 className='mb-10 text-gray-300'>Share it with someone who can participate.</h2>
						{/* <p className="mb-6 text-gray-300">
                            Let your friends know about your campaign!
                        </p> */}
						<div className='flex items-center justify-center w-full space-x-4'>
							<a
								href={shareLink}
								target='_blank'
								rel='noopener noreferrer'
								className='px-6 py-2 font-bold text-black transition bg-yellow-500 rounded-sm hover:bg-yellow-600'
							>
								Share on X
							</a>
							<a
								href={explorerLink}
								target='_blank'
								rel='noopener noreferrer'
								className='px-4 py-2 font-bold text-white transition bg-gray-600 rounded-sm hover:bg-gray-700'
							>
								View on explorer
							</a>
						</div>
					</div>
				</div>
			)}
			<div className='bg-[#0D0D0D] border border-yellow-500/20 rounded-lg w-full max-w-lg flex flex-col h-[90vh] overflow-y-auto'>
				<div className='flex items-center justify-between p-4 border-b border-yellow-500/20'>
					<h3 className='text-lg font-bold'>Place Your Prediction</h3>
					<button onClick={onClose} className='text-gray-400 hover:text-white'>
						<X className='w-6 h-6' />
					</button>
				</div>

				<div className='flex-grow p-6 space-y-6 overflow-y-auto'>
					<img src={imageURL} alt='' className='object-cover w-full h-48 rounded-lg' />

					<div>
						<h4 className='mb-2 font-semibold'>{title}</h4>
						{descriptionJSX}
						<div className='flex items-center text-sm text-gray-400'>
							<AlertCircle className='w-4 h-4 mr-2' />
							Voting ends in {new Date(data.date).toLocaleString()}
						</div>
					</div>

					<div className='space-y-4'>
						<div>
							<label className='block mb-2 text-sm font-medium text-gray-400'>
								Select Amount (WODS)
							</label>
							<input
								type='range'
								min={minPrice}
								max={maxPrice}
								step={getStepValue(minPrice, maxPrice)} // Adjust the step for floating values
								value={sliderValue || minPrice}
								onChange={(e) => handleSliderChange(e.target.value)}
								className='w-full h-2 rounded-lg appearance-none cursor-pointer'
								style={{
									background: `linear-gradient(to right, #facc15 ${
										((sliderValue - minPrice) / (maxPrice - minPrice)) * 100
									}%, #e5e7eb3b ${((sliderValue - minPrice) / (maxPrice - minPrice)) * 100}%)`,
								}}
							/>
							<div className='flex justify-between mt-1 text-xs text-gray-500'>
								<span>{minPrice.toFixed(2)} WODS</span>
								<span>{maxPrice.toFixed(2)} WODS</span>
							</div>
						</div>

						<div>
							<label className='block mb-2 text-sm font-medium text-gray-400'>Amount (WODS)</label>
							<input
								type='number'
								step='0.01'
								value={amount}
								onChange={(e) => handleAmountChange(e.target.value)}
								className='w-full px-4 py-2 bg-black border rounded-lg border-yellow-500/20 focus:outline-none focus:border-yellow-500'
								placeholder={`Enter amount (${minPrice.toFixed(2)}-${maxPrice.toFixed(2)})...`}
							/>
						</div>

						<div className='p-4 rounded-lg bg-yellow-500/10'>
							<div className='flex justify-between mb-2 text-sm'>
								<span>Voting for</span>
								<span className={selectedIndex === 0 ? 'text-green-400' : 'text-red-400'}>
									{selectedIndex === 0 ? data.options1[0] : data.options2[0]}
								</span>
							</div>
							<div className='flex justify-between'>
								<span>Potential win</span>
								<span className='font-semibold text-yellow-500'>
									{final ? final.toFixed(2) : '-'} WODS
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='flex p-4 space-x-4 border-t border-yellow-500/20'>
					<button
						onClick={handleParticipateUser}
						className='w-1/2 px-6 py-3 font-semibold text-black transition-colors bg-yellow-500 rounded-lg hover:bg-yellow-400'
					>
						{loading ? (
							'Please wait...'
						) : link ? (
							<a
								href={`https://basescan.org/tx/${link}`}
								target='_blank'
								rel='noreferrer'
								className='flex items-center justify-center gap-x-2'
							>
								View on explorer
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
									className='size-5'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244'
									/>
								</svg>
							</a>
						) : (
							'Confirm Prediction'
						)}
					</button>
					<button
						className='items-center justify-center hidden w-1/2 px-4 py-3 space-x-2 text-center align-middle rounded-lg md:flex bg-yellow-500/15 hover:underline'
						onClick={() => window.open(CONFIG.BUY_WODS_LINK, '_blank')}
					>
						Buy
						<span className='flex items-center'>
							<FaDollarSign />
							WODS
						</span>
						<FiExternalLink />
					</button>
				</div>
				<div className='flex items-center justify-between p-2 text-sm text-center text-gray-400'>
					△ You can place a bet only once with this wallet. Hit your best
				</div>
			</div>
		</div>
	);
}
