import {
    useAppKitAccount,
    useAppKitNetworkCore,
    useAppKitProvider,
} from '@reown/appkit/react';
import { Clock, ImagePlus } from 'lucide-react';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { CHAIN_CONFIG } from '../../../../config/config.wods';
import writeToContract from '../../../../services/api.deployMarket';
import AnnounceCampaign from './AnnounceCampaign/index';

const CampaignFormUI = () => {
    const [imagePreview, setImagePreview] = useState('');
    const [selected, setSelected] = useState(0);
    const [formData, setFormData] = useState({
        image: null,
        name: '',
        description: '',
        outcomes: ['Yes', 'No'],
        referenceLink: '',
        minBet: '',
        maxBet: '',
        endTime: '',
        category: '',
    });

    const categories = [
        'crypto',
        'technology',
        'culture',
        'politics',
        'sports',
        'breaking',
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [shareLink, setShareLink] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const { isConnected, address } = useAppKitAccount();
    const { walletProvider } = useAppKitProvider('eip155');
    const { chainId } = useAppKitNetworkCore();

    const customToastStyle = {
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                // 2MB size limit
                toast.error(
                    'Image size should be less than 2MB',
                    customToastStyle
                );
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setFormData((prev) => ({ ...prev, image: file }));
        }
    };

    const handleDeployContract = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const {
                image,
                name,
                description,
                outcomes,
                endTime,
                minBet,
                maxBet,
                category,
            } = formData;

            // Validate required fields
            if (
                !name ||
                !description ||
                !outcomes.length ||
                !endTime ||
                !minBet ||
                !maxBet
            ) {
                throw new Error('Please fill out all fields correctly.');
            }

            // Validate outcomes
            if (outcomes.some((outcome) => !outcome.trim())) {
                throw new Error('Outcomes cannot be empty.');
            }

            // Convert end time to a timestamp
            const endTimestamp = Math.floor(new Date(endTime).getTime() / 1000);

            // Call the writeToContract function

            const DescriptionJSON = {
                name,
                description,
                category,
            };

            const result = await writeToContract({
                address,
                isConnected,
                chainId,
                walletProvider,
                imageFile: image,
                DescriptionJSON,
                outcomes,
                token_decimal: 18, // Token decimal, assuming 18 for WOODS_TOKEN
                endTimestamp,
                minPrice: parseFloat(minBet),
                maxPrice: parseFloat(maxBet),
            });
            // Success handling
            if (result) {
                const link = `${CHAIN_CONFIG.blockExplorerUrls[0]}tx/${result.hash}`;
                toast.success('Campaign deployed successfully!', {
                    ...customToastStyle,
                    duration: 5000,
                });

                const WODS_LINK = `https://${window.location.host}/predict/${result.hash}`;
                setShareLink(
                    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        `New prediction market is live on Base.\nWhat do you think?\n${name}\nbet here 👉 ${WODS_LINK}`
                    )}`
                );
                setShowSharePopup(true);
                setExplorerLink(link);

                setFormData({
                    image: null,
                    name: '',
                    description: '',
                    outcomes: ['Yes', 'No'],
                    referenceLink: '',
                    minBet: '',
                    maxBet: '',
                    endTime: '',
                    category: '',
                });
                setImagePreview('');
            }
        } catch (err) {
            // Error handling
            const errorMessage = 'Something went wrong, please try again.';
            setError(errorMessage);
            toast.error('Something went wrong.', customToastStyle);
            console.error('Deployment error:', errorMessage);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className="p-4 pt-8 rounded-lg shadow-xl">
            {showSharePopup && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative p-6 py-10 text-center bg-gray-800 rounded-lg shadow-lg">
                        <button
                            onClick={() => setShowSharePopup(false)}
                            className="absolute top-[-8px] right-[-8px] bg-yellow-500 text-black w-6 h-6 flex items-center justify-center rounded-full hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-white"
                            aria-label="Close"
                        >
                            X
                        </button>
                        <h2 className="mb-2 text-lg font-bold text-white">
                            Yeahhhh! Your market is live
                        </h2>
                        <h2 className="mb-10 text-gray-300">
                            Share it with someone who can participate.
                        </h2>
                        {/* <p className="mb-6 text-gray-300">
                            Let your friends know about your campaign!
                        </p> */}
                        <div className="flex items-center justify-center w-full space-x-4">
                            <a
                                href={shareLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-6 py-2 font-bold text-black transition bg-yellow-500 rounded-sm hover:bg-yellow-600"
                            >
                                Share on X
                            </a>
                            <a
                                href={explorerLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-2 font-bold text-white transition bg-gray-600 rounded-sm hover:bg-gray-700"
                            >
                                View on explorer
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-col justify-start gap-4 p-4 mb-8 bg-gray-900 border-gray-700 rounded-lg items-left md:flex-row">
                {/* Tab 1 */}
                <button
                    className={`text-lg font-semibold px-6 py-2 transition-all duration-300 border-r-yellow-600 border-r ${
                        selected === 0
                            ? 'text-yellow-700 hover:text-white'
                            : ' text-gray-400 hover:text-white'
                    }`}
                    onClick={() => setSelected(0)}
                >
                    Launch New Market
                </button>

                {/* Tab 2 */}
                <button
                    className={`text-lg font-semibold px-6 py-2 rounded-lg transition-all duration-300 ${
                        selected === 1
                            ? 'text-yellow-700'
                            : 'text-gray-400 hover:text-white'
                    }`}
                    onClick={() => setSelected(1)}
                >
                    Created markets
                </button>
            </div>

            {selected === 0 ? (
                <>
                    <form
                        onSubmit={handleDeployContract}
                        className="grid grid-cols-1 gap-8 lg:grid-cols-2"
                    >
                        {/* Left Column - Image and Campaign Details */}
                        <div className="flex flex-col space-y-6">
                            {/* Image Upload - Increased prominence */}
                            <div className="flex-grow">
                                <label className="block mb-2 text-sm font-medium text-gray-200">
                                    Market Image
                                </label>
                                <div className="border-2 border-dashed border-gray-600 rounded-lg p-4 text-center h-full min-h-[200px] flex items-center justify-center">
                                    <input
                                        type="file"
                                        id="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="hidden"
                                    />
                                    <label
                                        htmlFor="image"
                                        className="block w-full h-full cursor-pointer"
                                    >
                                        {imagePreview ? (
                                            <img
                                                src={imagePreview}
                                                alt="Preview"
                                                className="object-contain max-h-full mx-auto rounded-lg"
                                            />
                                        ) : (
                                            <div className="flex flex-col items-center justify-center h-full">
                                                <ImagePlus className="w-12 h-12 mx-auto text-gray-400" />
                                                <span className="block mt-2 text-sm text-gray-400">
                                                    Click to upload image (max
                                                    5MB)
                                                </span>
                                            </div>
                                        )}
                                    </label>
                                </div>
                            </div>
                            {/* Campaign Name */}
                            <div>
                                <label className="block my-4 text-sm font-medium text-gray-200">
                                    Market Name ({formData.name.length}/100)
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter market name"
                                    value={formData.name}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            name: e.target.value.slice(0, 100),
                                        }))
                                    }
                                    className="w-full px-4 py-2 text-gray-200 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            {/* Outcomes - Expanded to fill more space */}
                            <div className="flex-grow">
                                <label className="block mb-2 text-sm font-medium text-gray-200">
                                    Outcomes
                                </label>
                                <div className="grid h-full grid-cols-1 gap-4 sm:grid-cols-2">
                                    {formData.outcomes.map((outcome, index) => (
                                        <input
                                            key={index}
                                            value={outcome}
                                            onChange={(e) => {
                                                const newOutcomes = [
                                                    ...formData.outcomes,
                                                ];
                                                newOutcomes[index] =
                                                    e.target.value;
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    outcomes: newOutcomes,
                                                }));
                                            }}
                                            placeholder={`Outcome ${index + 1}`}
                                            className="h-12 px-4 py-2 text-gray-200 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Outcomes and Additional Details */}
                        <div className="flex flex-col space-y-6">
                            {/* Campaign Description */}
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-200">
                                    Market Description (
                                    {formData.description.length}/180)
                                </label>
                                <textarea
                                    placeholder="Market description"
                                    value={formData.description}
                                    maxLength={180}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            description: e.target.value.slice(
                                                0,
                                                180
                                            ),
                                        }))
                                    }
                                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[150px] text-gray-200"
                                    required
                                />
                            </div>

                            {/* Dropdown for Categories */}
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-200">
                                    Select Category
                                </label>
                                <select
                                    value={formData.category}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            category: e.target.value,
                                        }))
                                    }
                                    className="w-full px-4 py-2 text-gray-200 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                >
                                    <option value="" disabled>
                                        Choose a category
                                    </option>
                                    {categories.map((category, index) => (
                                        <option key={index} value={category}>
                                            {category.charAt(0).toUpperCase() +
                                                category.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Prediction End Time */}
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-200">
                                    Prediction End Time
                                </label>
                                <div className="relative">
                                    <Clock className="absolute w-4 h-4 text-gray-400 left-3 top-3" />
                                    <input
                                        type="datetime-local"
                                        value={formData.endTime}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                endTime: e.target.value,
                                            }))
                                        }
                                        className="w-full py-2 pl-10 pr-4 text-gray-200 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                            </div>
                            {/* Bet Amounts */}
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-200">
                                        Minimum Bet
                                    </label>
                                    <div className="relative">
                                        <span className="absolute left-3 top-[10px] text-gray-400">
                                            $WODS
                                        </span>
                                        <input
                                            type="number"
                                            step="0.01"
                                            placeholder="0.00"
                                            value={formData.minBet}
                                            onChange={(e) =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    minBet: e.target.value,
                                                }))
                                            }
                                            className="w-full py-2 pl-20 pr-4 text-gray-200 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-200">
                                        Maximum Bet
                                    </label>
                                    <div className="relative">
                                        <span className="absolute left-3 top-[10px] text-gray-400">
                                            $WODS
                                        </span>
                                        <input
                                            type="number"
                                            step="0.01"
                                            placeholder="0.00"
                                            value={formData.maxBet}
                                            onChange={(e) =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    maxBet: e.target.value,
                                                }))
                                            }
                                            className="w-full py-2 pl-20 pr-4 text-gray-200 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Error Display */}
                            {error && (
                                <div className="p-4 bg-red-900 border-l-4 border-red-500">
                                    <p className="text-red-200">{error}</p>
                                </div>
                            )}

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full px-4 py-3 text-white transition-colors duration-200 bg-yellow-700 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                                disabled={isLoading}
                            >
                                {isLoading
                                    ? 'Deploying...'
                                    : 'Create Prediction Market'}
                            </button>
                            <div className="max-w-3xl py-2 mx-auto text-center text-gray-500">
                                △ Creating new market takes 100,000 $WODS that
                                will be claimable once market is resolved. This
                                ensures timely resolution and trust for the
                                participants
                            </div>
                        </div>
                        <Toaster />
                    </form>
                </>
            ) : (
                <AnnounceCampaign />
            )}
        </div>
    );
};

export default CampaignFormUI;
