import { TrendingUp } from 'lucide-react';
import React from 'react';
import { FaDollarSign } from 'react-icons/fa6';
import { FiExternalLink } from 'react-icons/fi';
import CONFIG from '../../../../config/config.wods';
import RenderMarketCard from './TrendingMarketCards';

const TrendingMarkets = ({
	tokenData,
	onlyMarkets = false,
	predictionData,
	title = 'Trending Markets',
}) => {
	return (
		<section className='py-6'>
			{/* Header */}
			<div className='flex items-center justify-between mb-4'>
				{predictionData?.length > 0 && (
					<div className='flex items-center'>
						<div className='flex flex-col'>
							<h2 className='flex items-center text-xl font-bold align-middle'>
								<TrendingUp className='w-5 h-5 mr-2 text-yellow-500' />
								{title}
							</h2>
						</div>
					</div>
				)}
				{!onlyMarkets && (
					<div className='flex flex-col'>
						<div className='flex justify-end w-full button-container'>
							<button
								className='items-center hidden px-4 py-3 rounded-md md:flex bg-yellow-500/15'
								onClick={() => window.open(CONFIG.BUY_WODS_LINK, '_blank')}
							>
								Buy
								<FaDollarSign />
								WODS&nbsp;
								<FiExternalLink />
							</button>
						</div>
						<div className=''>
							{tokenData?.loading ? (
								<span className='text-gray-400'>Loading...</span>
							) : tokenData?.error ? (
								<span className='text-red-400'>{tokenData.error}</span>
							) : (
								<>
									<span className='text-yellow-500'>$WODS:</span>
									<span className='ml-2'>$ {tokenData.price}</span>
									<span
										className={`ml-2 ${
											tokenData.priceChange > 0 ? 'text-green-400' : 'text-red-400'
										}`}
									>
										{tokenData.priceChange > 0 ? '+' : ''}
										{tokenData.priceChange}%
									</span>
								</>
							)}
						</div>
					</div>
				)}
			</div>

			<div className='flex flex-row gap-4 overflow-x-scroll no-scrollbar'>
				{predictionData?.slice(0, 3).map((market, index) => (
					<RenderMarketCard market={market} index={index} />
				))}
			</div>
		</section>
	);
};

export default TrendingMarkets;
