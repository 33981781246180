import { BrowserProvider, ethers, JsonRpcSigner } from 'ethers';
import { ADMIN_FACTORY_ABI } from '../config/abi/admin.abi';
import { TOKEN_CONTRACT_ABI } from '../config/abi/token.abi';
import {
    FACTORY_CONTRACT_ADDRESS,
    TOKEN_LOCKING_CONTRACT,
    WOODS_TOKEN,
} from '../config/config.wods';
import { uploadImageToPinataCloud } from './api.uploadImage';

const writeToContract = async ({
    imageFile,
    DescriptionJSON,
    outcomes,
    token_decimal,
    endTimestamp,
    minPrice,
    maxPrice,
    address,
    walletProvider,
    chainId,
    isConnected,
}) => {
    if (!isConnected) {
        throw new Error('Wallet not connected.');
    }

    try {
        const provider = new BrowserProvider(walletProvider, chainId);
        const signer = new JsonRpcSigner(provider, address);
        // const provider = new ethers.BrowserProvider(window.ethereum);
        // await window.ethereum.request({ method: 'eth_requestAccounts' });
        // await provider.send('wallet_addEthereumChain', [CHAIN_CONFIG]);
        // await provider.send('wallet_switchEthereumChain', [{ chainId: CHAIN_CONFIG.chainId }]);

        // const signer = await provider.getSigner();
        const approveContract = new ethers.Contract(
            WOODS_TOKEN,
            TOKEN_CONTRACT_ABI,
            signer
        );
        const approvalAmount = ethers.parseUnits('100000', 18);
        const approveResponse = await approveContract.approve(
            TOKEN_LOCKING_CONTRACT,
            approvalAmount
        );
        await approveResponse.wait();

        const { IPFS_HASH } = await uploadImageToPinataCloud(imageFile);
        const desc = JSON.stringify({
            ...DescriptionJSON,
            'image-hash': IPFS_HASH,
        });
        const contract = new ethers.Contract(
            FACTORY_CONTRACT_ADDRESS,
            ADMIN_FACTORY_ABI,
            signer
        );
        const tx = await contract.deployMarket(
            WOODS_TOKEN,
            desc,
            outcomes,
            token_decimal,
            endTimestamp,
            ethers.parseUnits(minPrice.toString(), 18),
            ethers.parseUnits(maxPrice.toString(), 18)
        );
        const receipt = await tx.wait();
        const [platformAddress, platformFee] =
            await contract.getPlatformDetails();

        return {
            hash: receipt?.hash,
            address: receipt?.logs[0]?.address,
            platformAddress,
            platformFee: Number(platformFee),
        };
    } catch (err) {
        console.error('Error:', err);
        throw new Error(err);
    }
};

export default writeToContract;
