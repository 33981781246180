import { BrowserProvider, ethers, JsonRpcSigner } from 'ethers';
import { TOKEN_LOCKING_ABI } from '../config/abi/locking.abi';
import { TOKEN_LOCKING_CONTRACT } from '../config/config.wods';

export const unlockFunds = async ({
	address,
	walletProvider,
	chainId,
	marketAddress,
	isConnected,
}) => {
	try {
		if (!isConnected) {
			throw new Error('Wallet not connected.');
		}
		const provider = new BrowserProvider(walletProvider, chainId);
		const signer = new JsonRpcSigner(provider, address);
		const unlockFundsContractInstace = new ethers.Contract(
			TOKEN_LOCKING_CONTRACT,
			TOKEN_LOCKING_ABI,
			signer
		);
		const unlockFundsTrx = await unlockFundsContractInstace.unlockTokens(marketAddress);
		const receipt = await unlockFundsTrx.wait();

		return receipt?.hash;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};
