import { Coins, Wallet } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchProfilePageData } from '../../../services/api.profileData';
import { StoreNames } from '../../../store';
import { setAddress } from '../../../store/reducers/addressReducers';

const MARKET_TABS = {
	created: 'Markets Created',
	participated: 'Markets Participated',
};

const MarketTabs = ({ activeTab, onTabChange }) => {
	return (
		<div className='flex flex-col gap-4 md:flex-row md:items-center'>
			{Object.keys(MARKET_TABS).map((key) => (
				<button
					key={key}
					onClick={() => onTabChange(key)}
					className={`px-4 py-2 rounded-md ${
						activeTab === key ? 'bg-yellow-500 text-black' : 'bg-yellow-500/20 text-yellow-500'
					}`}
				>
					{MARKET_TABS[key]}
				</button>
			))}
		</div>
	);
};

const MarketList = ({ markets, activeTab }) => (
	<div className='space-y-4'>
		{markets?.length > 0 ? (
			markets.map((market) => {
				const predictionDate = new Date(market.date);
				const isActive = predictionDate > new Date();
				const status = isActive ? 'Active' : 'Ended';

				return (
					<Link to={`/predict/${market.address}`} key={market.address || market.title}>
						<div className='flex gap-4 p-4 border rounded-lg cursor-pointer border-yellow-500/20'>
							<img
								src={market.image || '/default-image.png'}
								alt={market.title || 'Market Image'}
								className='object-cover w-24 h-24 mt-4 rounded-lg'
							/>
							<div className='flex flex-col justify-between flex-grow'>
								<div>
									<h3 className='text-lg font-bold'>{market.title || 'Untitled Market'}</h3>
									<p className='text-sm text-gray-400'>Status: {status}</p>
									<p className='text-sm text-gray-400'>
										Date: {market.date ? predictionDate.toLocaleDateString() : 'N/A'}
									</p>
									{activeTab === 'created' && (
										<p className='text-sm text-yellow-500'>Votes: {market.totalBets || 0}</p>
									)}
								</div>
								{activeTab === 'participated' && (
									<div className=''>
										<p className='text-sm text-gray-400'>
											Participation Amount: {market.betAmount || 0} $WODS
										</p>
										<p className={'text-sm text-yellow-400'}>{market.status}</p>
										{/* <button className="px-4 py-2 mt-2 text-white bg-green-500 rounded-md">
                                        Withdraw Funds
                                    </button> */}
									</div>
								)}
							</div>
						</div>
					</Link>
				);
			})
		) : (
			<p>No markets available.</p>
		)}
	</div>
);

export function ProfilePage() {
	const [activeTab, setActiveTab] = useState('created');
	const [profileData, setProfileData] = useState(null);
	const { address } = useParams();
	const navigate = useNavigate();

	// Redirect if wallet is not connected
	useEffect(() => {
		if (!address) {
			navigate('/predict');
		}
	}, [address, navigate]);

	useEffect(() => {
		if (!address) return;
		fetchProfilePageData(address)
			.then((res) => {
				setProfileData(res);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [address]);

	if (!profileData) {
		return <div>Loading...</div>;
	}

	const { ethBalance, wodsBalance, createdMarkets, participatedMarkets } = profileData;
	return (
		<div className='container px-4 py-8 mx-auto'>
			<h1 className='mb-8 text-2xl font-bold'>Profile</h1>
			<div className='space-y-8'>
				<WalletInfo ethBalance={ethBalance} wodsBalance={wodsBalance} />
				<div className='space-y-6'>
					<MarketTabs activeTab={activeTab} onTabChange={setActiveTab} />
					<MarketList
						markets={activeTab === 'created' ? createdMarkets : participatedMarkets}
						activeTab={activeTab}
					/>
				</div>
			</div>
		</div>
	);
}

export function WalletInfo({ ethBalance, wodsBalance }) {
	const dispatch = useDispatch();
	const { address } = useSelector((state) => state[StoreNames.ADDRESS]);

	const handleDisconnectWallet = () => {
		dispatch(setAddress(null));
	};

	return (
		<div className='p-6 space-y-6 bg-black border rounded-lg border-yellow-500/20'>
			<div className='flex flex-col items-center justify-between gap-4 sm:flex-row'>
				<div className='flex items-center'>
					<div className='flex items-center justify-center w-12 h-12 rounded-full bg-yellow-500/20'>
						<Wallet className='w-6 h-6 text-yellow-500' />
					</div>
					<div>
						<div className='text-lg font-bold'> &nbsp;Wallet Address</div>
					</div>
				</div>
				<button
					className='w-full max-w-sm px-4 py-2 text-red-400 transition-colors rounded-md bg-red-500/20 hover:bg-red-500/30'
					onClick={handleDisconnectWallet}
				>
					Disconnect
				</button>
			</div>
			<div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
				<div className='p-4 rounded-lg bg-yellow-500/10'>
					<div className='flex items-center justify-between mb-2'>
						<span className='text-gray-400'>WODS Balance</span>
						<Coins className='w-5 h-5 text-yellow-500' />
					</div>
					<div className='text-2xl font-bold'>{wodsBalance || 0} $WODS</div>
				</div>
				<div className='p-4 rounded-lg bg-yellow-500/10'>
					<div className='flex items-center justify-between mb-2'>
						<span className='text-gray-400'>ETH Balance</span>
						<Wallet className='w-5 h-5 text-yellow-500' />
					</div>
					<div className='text-2xl font-bold'>{ethBalance || 0} $ETH</div>
				</div>
			</div>
		</div>
	);
}
